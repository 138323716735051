import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  home: [],
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    loadReports(state, action) {
      state.home = action.payload;
    },
  },
});

export const { loadReports } = slice.actions;

export default slice.reducer;

// *****************************************************

export function getHomeReports() {
  return async dispatch => {
    try {
      const response = await axios.post('/api/status-sign-process');
      const { data } = response.data;
      dispatch(slice.actions.loadReports(data));
    } catch (e) {
      if (e.response.status >= 400) {
        window.location.reload();
      }
    }
  };
}
