import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import { MIcon } from '../../../theme';
import { FaUsers } from "react-icons/fa";


const path = name => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_edit_file')} />,
  users: <MIcon src={path('ic_user')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  page: <MIcon src={path('file-document-multiple')} />,
  editor: <MIcon src={path('ic_editor')} />,
  charts: <MIcon src={path('ic_charts')} />,
  signers: <FaUsers style={{fontSize: "24px"}} />
};

const navConfig = [
  {
    subheader: 'general',
    items: [
      {
        title: 'Iniciar Proceso',
        icon: ICONS.dashboard,
        href: PATH_APP.general.root,
        permission: true,
      },
      {
        title: 'Documentos',
        icon: ICONS.page,
        href: PATH_APP.app.documents,
      },
      {
        title: 'Firmantes',
        icon: ICONS.signers,
        href: PATH_APP.app.signers,
        admin: true,
        permission: true,
      },
      {
        title: 'Usuarios',
        icon: ICONS.users,
        href: PATH_APP.app.users,
        admin: true,
      },
      {
        title: 'Plan',
        icon: ICONS.charts,
        href: PATH_APP.app.plan,
        admin: true,
      },
    ],
  },
];

export default navConfig;
