import axios from 'axios';

// const BASE_URL = process.env.REACT_APP_SERVER;

const protocole = window.location.protocol;
const host_client = window.location.hostname.split('.')[0];

let apiBase = '';

if (window.location.hostname.indexOf('.localhost') >= 0) {
  apiBase = `${protocole}//${host_client}.api.dev.localhost:8080`;
} else if (window.location.hostname.indexOf('.dev.') >= 0) {
  apiBase = `${protocole}//${host_client}.api.dev.polysign.pe`;
} else {
  apiBase = `${protocole}//${host_client}.api.polysign.pe`;
}

// apiBase = 'http://polysistemas.api.dev.localhost:8080'
// apiBase = 'https://polysistemas.api.dev.polysign.pe'


axios.defaults.baseURL = apiBase;
axios.defaults.headers.post['Accept'] = '*/*';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;

axios.interceptors.request.use(
  response => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios.post(`${apiBase}/api/token/refresh/`, { refresh: refreshToken }).then(res => {
        if (res.status === 200) {
          localStorage.setItem('accessToken', res.data.access);
          return axios(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  },
);

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: apiBase,
});

axiosInstance.interceptors.response.use(
  response => response,
  error => Promise.reject((error.response && error.response.data) || error),
);

export default axiosInstance;
