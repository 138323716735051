import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('src/views/general/DashboardAppView')),
    },
    {
      exact: true,
      path: PATH_APP.general.ecommerce,
      component: lazy(() => import('src/views/general/DashboardEcommerceView')),
    },
    {
      exact: true,
      path: PATH_APP.general.analytics,
      component: lazy(() => import('src/views/general/DashboardAnalyticsView')),
    },
    {
      exact: true,
      path: PATH_APP.general.firm,
      component: lazy(() => import('src/views/firm/firmView')),
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />,
    },
    // CUSTOM : MENU
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.documents,
      component: lazy(() => import('src/views/documents/documentsView')),
    },
    {
      exact: true,
      path: PATH_APP.app.users,
      component: lazy(() => import('src/views/user/UserListView')),
    },
    {
      exact: true,
      path: PATH_APP.app.update,
      component: lazy(() => import('src/views/user/UserView')),
    },
    {
      exact: true,
      path: PATH_APP.app.signers,
      component: lazy(() => import('src/views/signer/UserListView')),
    },
    {
      exact: true,
      path: PATH_APP.app.signerUpdate,
      component: lazy(() => import('src/views/signer/UserView')),
    },
    {
      exact: true,
      path: PATH_APP.app.plan,
      component: lazy(() => import('src/views/general/DashboardAnalyticsView')),
    },
    // MANAGEMENT : E-COMMERCE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.eCommerce.products,
      component: lazy(() => import('src/views/e-commerce/ShopView')),
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.product,
      component: lazy(() => import('src/views/e-commerce/ProductDetailsView')),
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.list,
      component: lazy(() => import('src/views/e-commerce/ProductListView')),
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.checkout,
      component: lazy(() => import('src/views/e-commerce/CheckoutView')),
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.invoice,
      component: lazy(() => import('src/views/e-commerce/InvoiceView')),
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.root,
      component: () => <Redirect to={PATH_APP.management.eCommerce.products} />,
    },

    // MANAGEMENT : BLOG
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.blog.root,
      component: lazy(() => import('src/views/blog/BlogView')),
    },
    {
      exact: true,
      path: PATH_APP.management.blog.post,
      component: lazy(() => import('src/views/blog/PostDetailsView')),
    },
    {
      exact: true,
      path: PATH_APP.management.blog.newPost,
      component: lazy(() => import('src/views/blog/NewPostView')),
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.user.profile,
      component: lazy(() => import('src/views/user/ProfileView')),
    },
    {
      exact: true,
      path: PATH_APP.management.user.cards,
      component: lazy(() => import('src/views/user/UserCardsView')),
    },
    {
      exact: true,
      path: PATH_APP.management.user.list,
      component: lazy(() => import('src/views/user/UserListView')),
    },
    {
      exact: true,
      path: PATH_APP.management.user.account,
      component: lazy(() => import('src/views/user/AccountView')),
    },
    {
      exact: true,
      path: PATH_APP.management.user.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />,
    },
    {
      exact: true,
      path: PATH_APP.management.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />,
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to='/404' />,
    },
  ],
};

export default AppRoutes;
