import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firmDocument: null,
  refetch: false,
};

const slice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    loadDocument: (state, action) => {
      state.firmDocument = action.payload;
    },
    startRefetch: state => {
      state.refetch = true;
    },
    stopRefetch: state => {
      state.refetch = false;
    },
  },
});

export default slice.reducer;

export const { loadDocument, startRefetch, stopRefetch } = slice.actions;

export const getFirmDocument = state => state.document.firmDocument;

export const getRefetchStatus = state => state.document.refetch;
