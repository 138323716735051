import { createSlice } from '@reduxjs/toolkit';

const defaultList = [{ id: 1, dni: '', name: '', lastname: '', email: '', type_doc: 0 }];

const initialState = {
  list: defaultList,
  idCount: 1,
  valid: [false],
  config: {
    types: [],
    orders: [],
  },
};

const slice = createSlice({
  name: 'signers',
  initialState,
  reducers: {
    resetSigners: state => {
      state.list = defaultList;
      state.idCount = 1;
      state.valid = [false];
    },
    addSigner: (state, action) => {
      const isSigner = state.list.find(signer => signer.id === action.payload.id);
      if (!isSigner) {
        state.list = state.list.concat(action.payload);
        state.idCount = state.idCount + 1;
        state.valid = state.valid.concat(false);
      }
    },
    updateSigner: (state, action) => {
      state.list.splice(action.payload.index, 1, action.payload.signer);
    },
    removeSigner: (state, action) => {
      state.list = state.list.filter(signer => signer.id !== action.payload.id);
      state.valid.splice(action.payload.index, 1);
    },
    updateValid: (state, action) => {
      state.valid.splice(action.payload.index, 1, action.payload.valid);
    },
    loadConfig: (state, action) => {
      state.config.types = action.payload.types;
      state.config.orders = action.payload.orders;
    },
  },
});

export default slice.reducer;

export const {
  resetSigners,
  addSigner,
  updateSigner,
  removeSigner,
  updateValid,
  loadConfig,
} = slice.actions;

// slice
export const getSigners = state => state.signers.list;

export const getIdCount = state => state.signers.idCount;

export const getValid = state => state.signers.valid;

export const getFirmConfig = state => state.signers.config;
