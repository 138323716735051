import jwtDecode from 'jwt-decode';
// import axios from 'src/utils/axios';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: { displayName: '', message: '' },
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    },

    // UPDATE ACCOUNT
    updateProfile(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function login({ email, password }) {
  return async dispatch => {
    const response = await axios.post('/api/token/', {
      username: email,
      password,
    });

    const { refresh, access } = response.data;

    setSession(access, refresh);
    dispatch(slice.actions.loginSuccess({ user: { email } }));
  };
}

// ----------------------------------------------------------------------

export function register({ email, password, firstName, lastName }) {
  return async dispatch => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { access, user } = response.data;

    window.localStorage.setItem('accessToken', access);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async dispatch => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axios.post('/api/account');

        const {
          data: { user, polysignkey },
        } = response.data;

        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: { displayName: user?.first_name, ...user, polysignkey },
          }),
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null,
          }),
        );
      }
    } catch (error) {
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
        }),
      );
    }
  };
}

// ********************************

export function updateProfile({ first_name, last_name }) {
  return async dispatch => {
    const response = await axios.post('/api/account/update', {
      first_name,
      last_name,
    });

    const { message } = response.data;

    dispatch(
      slice.actions.updateProfile({ first_name, last_name, message, displayName: first_name }),
    );
  };
}
